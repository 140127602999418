import { QrCodeIcon } from '@heroicons/react/20/solid';
import { ImageIcon, Link1Icon } from '@radix-ui/react-icons';
import { CheckSquare2Icon } from 'lucide-react';

export default function UIElementRenderer({ element }: { element: UIElement }) {
    const { type } = element;

    switch (type) {
        case 'text':
            return <UIText {...(element.metadata as UITextMetadata)} />;
        case 'dynamicText':
            return (
                <UIDynamicText
                    {...(element.metadata as UIDynamicTextMetadata)}
                />
            );
        case 'button':
            return <UIButton {...(element.metadata as UIButtonMetadata)} />;
        case 'image':
            return <UIImage {...(element.metadata as UIImageMetadata)} />;
        case 'input':
            return <UIInput {...(element.metadata as UIInputMetadata)} />;
        case 'outputPreview':
            return (
                <UIOutputPreview
                    {...(element.metadata as UIOutputPreviewMetadata)}
                />
            );
        case 'currentSessionQR':
            return (
                <UICurrentSessionQR
                    {...(element.metadata as UICurrentSessionQRMetadata)}
                />
            );
    }
    return null;
}

function UIInput({ label, inputType, cornerRadius, width }: UIInputMetadata) {
    if (inputType === 'bool') {
        return (
            <div className="flex items-center gap-2">
                <CheckSquare2Icon />
                {label}
            </div>
        );
    }
    if (inputType === 'text') {
        return (
            <div className="flex items-center gap-2 text-black">
                <input
                    type="text"
                    placeholder={label || ''}
                    style={{
                        borderRadius: `${cornerRadius}px`,
                        width: `${width}px`,
                    }}
                    className={` bg-white-50`}
                />
            </div>
        );
    }
    return null;
}

function UIOutputPreview({ width, height }: UIOutputPreviewMetadata) {
    return <ImageIcon width={width} height={height} />;
}

function UICurrentSessionQR({
    width,
    height,
    foregroundColor,
    backgroundColor,
}: UICurrentSessionQRMetadata) {
    return (
        <QrCodeIcon
            width={width}
            height={height}
            style={{ color: foregroundColor, backgroundColor: backgroundColor }}
        />
    );
}

function UIText({
    text,
    fontSize,
    fontWeight,
    fontFace,
    verticalOffset,
    horizontalOffset,
}: UITextMetadata) {
    return (
        <div
            style={{
                fontSize: fontSize,
                fontWeight: fontWeight,
                fontFamily: fontFace,
                lineHeight: `${fontSize ? fontSize * 1.2 : 0}px`,
                transform: `translate(${horizontalOffset || 0}px, ${
                    verticalOffset || 0
                }px)`,
            }}
        >
            {text}
        </div>
    );
}

function UIDynamicText({
    identifier,
    fontSize,
    fontWeight,
    fontFace,
    verticalOffset,
    horizontalOffset,
}: UIDynamicTextMetadata) {
    return (
        <div
            style={{
                fontSize: fontSize,
                fontWeight: fontWeight,
                fontFamily: fontFace,
                transform: `translate(${horizontalOffset || 0}px, ${
                    verticalOffset || 0
                }px)`,
                lineHeight: `${fontSize ? fontSize * 1.2 : 0}px`,
            }}
        >
            {identifier}
        </div>
    );
}

function UIButton({
    text,
    action,
    destination,
    value,
    valueKey,
    imageUrl,
    fontSize,
    fontWeight,
    fontFace,
    horizontalMargin,
    verticalMargin,
    horizontalPadding,
    verticalPadding,
    borderSize,
    borderColor,
    cornerRadius,
    verticalOffset,
    horizontalOffset,
}: UIButtonMetadata) {
    return (
        <div
            style={{
                backgroundColor: `${imageUrl ? 'transparent' : '#000'}`,
                color: '#fff',
                padding: `${verticalPadding || 0}px ${
                    horizontalPadding || 0
                }px`,
                borderRadius: `${cornerRadius || 0}px`,
                cursor: 'pointer',
                margin: `${verticalMargin || 0}px ${horizontalMargin || 0}px`,
                border: `${borderSize || 0}px solid ${borderColor || '#000'}`,
                fontSize: `${fontSize || 0}px`,
                fontWeight: fontWeight || 0,
                fontFamily: fontFace || 'system',
                transform: `translate(${horizontalOffset || 0}px, ${
                    verticalOffset || 0
                }px)`,
                overflow: 'visible',
            }}
        >
            {action === 'navigate' && (
                <div
                    className="absolute bg-black rounded-[99999px] p-1 m-1 border border-white opacity-50 hover:opacity-100 transition-all duration-300 cursor-pointer"
                    aria-label={`Navigate to ${destination}`}
                    data-tooltip-target={`destination-${destination}-tooltip`}
                >
                    <Link1Icon />
                </div>
            )}
            {imageUrl && <img src={imageUrl} alt={text} />}
            {!imageUrl && text}
        </div>
    );
}

function UIImage({
    imageUrl,
    verticalOffset,
    horizontalOffset,
}: UIImageMetadata) {
    return (
        <div
            style={{
                transform: `translate(${horizontalOffset || 0}px, ${
                    verticalOffset || 0
                }px)`,
            }}
        >
            {imageUrl}
        </div>
    );
}

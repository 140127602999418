import { useParams } from 'react-router-dom';
import './Gallery.css';
import { useCallback, useMemo, useState } from 'react';
import GalleryShareContext from './GalleryShareContext';
import useSharingInteractions from './hooks/useSharingInteractions';
import GlobalStyle from '@/src/Share/components/GalleryTheme';
import { useLocalStorage } from '@/src/Common/components/hooks/useLocalStorage';
import { Button } from '@/src/shadComponents/ui/button';
import DeepLocalSettingsModal from './DeepLocalSettingsModal';
import DeepLocalGalleryView from './DeepLocalGalleryView';
import DeepLocalSharingStationModal from './DeepLocalSharingStationModal';

export default function DeepLocal() {
    const { slug } = useParams();
    const {
        gallery,
        isLoading,
        photos,
        delayed,
        expandedIndex,
        setExpandedIndex,
        swipeHandlers,
        loadedPhotos,
        getNewPage,
    } = useSharingInteractions(String(slug));

    const [showShareModal, setShowShareModal] = useState(false);
    const [shareIdentifiers, setShareIdentifiers] = useState<string[]>([]);
    const [deepLocalIPAddress, setDeepLocalIPAddress] = useLocalStorage<
        string | null
    >('deepLocalIPAddress', 'localhost');
    const [deepLocalPort, setDeepLocalPort] = useLocalStorage<string | null>(
        'deepLocalPort',
        '8080',
    );
    const [deepLocalEndpoint, setDeepLocalEndpoint] = useLocalStorage<
        string | null
    >('deepLocalEndpoint', '/api/upload');
    const [showSettingsModal, setShowSettingsModal] = useState(false);

    const onShareButtonClick = useCallback(
        (shareIdentifier: string, type: 0 | 1 | 2 | undefined) => {
            setShareIdentifiers([shareIdentifier]);
            setTimeout(() => {
                setShowShareModal(true);
            }, 50);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [],
    );

    const context = useMemo(
        () => ({
            setShowShareModal: onShareButtonClick,
            setHideShareModal: () => setShowShareModal(false),
            setExpandedIndex,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    if (!gallery && !isLoading) {
        return (
            <div>
                <h3>gallery not found...</h3>
            </div>
        );
    }

    return (
        <GalleryShareContext.Provider value={context}>
            <div className="Gallery-view">
                <GlobalStyle
                    foregroundColor={gallery?.foregroundColor}
                    backgroundColor={gallery?.backgroundColor}
                    headerBackgroundColor={gallery?.headerBackgroundColor}
                    headerForegroundColor={gallery?.headerForegroundColor}
                />
                <DeepLocalGalleryView
                    {...{
                        gallery,
                        isLoading,
                        photos,
                        delayed,
                        expandedIndex,
                        setExpandedIndex,
                        swipeHandlers,
                        loadedPhotos,
                        getNewPage,
                        galleryId: gallery?.id || 0,
                    }}
                />
                {gallery && showShareModal && (
                    <DeepLocalSharingStationModal
                        {...{
                            ipAddress: deepLocalIPAddress,
                            port: deepLocalPort,
                            setShowShareModal,
                            shareIdentifiers,
                            photos,
                            endpoint: deepLocalEndpoint,
                        }}
                    />
                )}
            </div>
            <div className="absolute bottom-0 right-0 z-[102]">
                <Button
                    onClick={() => setShowSettingsModal(true)}
                    className="Primary-button hide-after-timeout"
                >
                    Settings
                </Button>
            </div>
            {showSettingsModal && (
                <DeepLocalSettingsModal
                    deepLocalIPAddress={deepLocalIPAddress}
                    deepLocalPort={deepLocalPort}
                    setDeepLocalIPAddress={setDeepLocalIPAddress}
                    setDeepLocalPort={setDeepLocalPort}
                    setShowModal={setShowSettingsModal}
                    deepLocalEndpoint={deepLocalEndpoint}
                    setDeepLocalEndpoint={setDeepLocalEndpoint}
                />
            )}
        </GalleryShareContext.Provider>
    );
}

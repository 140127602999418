import { useState } from 'react';
import { CardContent } from '../shadComponents/ui/card';
import { Card } from '../shadComponents/ui/card';
import { PlusIcon } from 'lucide-react';
import { useDispatch } from 'react-redux';
import { createUIElement } from './utils/state/screensSlice';
import UIElementRenderer from './UIElementRenderer';
import DesignerToolbar from './DesignerToolbar';

export default function ScreenDesigner({
    currentScreen,
    onSelectElement,
    onRemoveElement,
}: {
    currentScreen: ScreenDefinition;
    onSelectElement: (index: number | null) => void;
    onRemoveElement: (index: number) => void;
}) {
    const dispatch = useDispatch();
    const [selectedElement, setSelectedElement] = useState<UIElement | null>(
        null,
    );

    const [showGridLines, setShowGridLines] = useState(true);
    const [showPresentation, setShowPresentation] = useState(false);
    return (
        <div className="fixed top-0 left-0 right-0 bottom-0 bg-black/50 w-full h-full flex items-center">
            <DesignerToolbar
                showGridLines={showGridLines}
                setShowGridLines={setShowGridLines}
                showPresentation={showPresentation}
                setShowPresentation={setShowPresentation}
            />
            <div className="p-4 rounded-md w-[66%] h-[75%] ">
                <div className="text-xl font-semibold text-gray-900">
                    <Card
                        className={`h-[80vh] w-[60vh] mx-auto z-1000`}
                        style={{
                            backgroundColor:
                                currentScreen?.displayProperties
                                    .backgroundColor,
                            color: currentScreen?.displayProperties
                                .foregroundColor,
                            backgroundImage: `url(${currentScreen?.displayProperties.backgroundMediaUrl})`,
                            backgroundSize: 'cover',
                            paddingTop:
                                (currentScreen?.displayProperties
                                    .verticalPadding || 0) /
                                    2 +
                                'px',
                            paddingBottom:
                                (currentScreen?.displayProperties
                                    .verticalPadding || 0) /
                                    2 +
                                'px',
                            paddingLeft:
                                (currentScreen?.displayProperties
                                    .horizontalPadding || 0) /
                                    2 +
                                'px',
                            paddingRight:
                                (currentScreen?.displayProperties
                                    .horizontalPadding || 0) /
                                    2 +
                                'px',
                        }}
                    >
                        <CardContent
                            className="h-full flex flex-col p-0"
                            onClick={() => onSelectElement(null)}
                        >
                            {['top', 'center', 'bottom'].map((vPos) => (
                                <div key={vPos} className="flex-1 flex">
                                    {['leading', 'center', 'trailing'].map(
                                        (hPos) => (
                                            <div
                                                key={`${vPos}-${hPos}`}
                                                className={`flex-1 flex items-center flex-col justify-center ${
                                                    showGridLines
                                                        ? 'border border-gray-700 border-[0.5px] border-dashed'
                                                        : ''
                                                }`}
                                            >
                                                {currentScreen.elements
                                                    .filter(
                                                        (element) =>
                                                            element.horizontalLocation ===
                                                                hPos &&
                                                            element.verticalLocation ===
                                                                vPos,
                                                    )
                                                    .map((element, index) => (
                                                        <div
                                                            key={index}
                                                            className={`cursor-pointer ${
                                                                element.id ===
                                                                selectedElement?.id
                                                                    ? 'outline outline-blue-200 outline-[2px] outline-offset-2'
                                                                    : ''
                                                            }`}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                let elementIndex =
                                                                    currentScreen.elements.findIndex(
                                                                        (e) =>
                                                                            e.id ===
                                                                            element.id,
                                                                    );
                                                                setSelectedElement(
                                                                    element,
                                                                );
                                                                onSelectElement(
                                                                    elementIndex,
                                                                );
                                                            }}
                                                        >
                                                            <UIElementRenderer
                                                                element={
                                                                    element
                                                                }
                                                            />
                                                        </div>
                                                    ))}
                                                {!showPresentation && (
                                                    <PlusIcon
                                                        className="w-6 h-6 bg-white/50 rounded-[99999px] mt-2 text-black border border-black hover:bg-white/70 hover:border-black/70 transition-all duration-300 cursor-pointer [&_*]:cursor-pointer"
                                                        onClick={() => {
                                                            dispatch(
                                                                createUIElement(
                                                                    {
                                                                        screenId:
                                                                            currentScreen.id,
                                                                        verticalLocation:
                                                                            vPos,
                                                                        horizontalLocation:
                                                                            hPos,
                                                                    },
                                                                ),
                                                            );
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        ),
                                    )}
                                </div>
                            ))}
                        </CardContent>
                    </Card>
                    <div className="flex justify-center items-center mt-2">
                        <span className="text-white text-xs">
                            Check on a device to validate the experience.
                            Elements may display differently based on screen
                            size.
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

import { Formik, Form, Field } from 'formik';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useCrowdGallery, { CrowdGallerySettings } from './hooks/useCrowdGallery';
import LoadingIndicator from '../../Common/components/LoadingIndicator';
import { Button } from '@/src/shadComponents/ui/button';

export default function EditCrowdGallery() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [settings, setSettings] = useState<CrowdGallerySettings | null>(null);
    const { getCrowdGallery, saveCrowdGallery } = useCrowdGallery();
    const { galleryId: id } = useParams();
    const galleryId = id ? +id : 0;

    useEffect(() => {
        let isMounted = true;
        const load = async () => {
            try {
                const settings: CrowdGallerySettings = await getCrowdGallery(
                    galleryId,
                );

                isMounted && setSettings(settings);
            } catch (error) {
                console.error(error);
            } finally {
                isMounted && setIsLoading(false);
            }
        };

        load();

        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLoading || !settings) {
        return <LoadingIndicator />;
    }

    return (
        <div>
            <div className="Row-container">
                <h1>edit crowd gallery settings</h1>
            </div>
            <Formik
                initialValues={settings}
                onSubmit={async (values, actions) => {
                    const wasSuccessful = await saveCrowdGallery(
                        settings.id,
                        values,
                    );
                    actions.setSubmitting(false);

                    if (wasSuccessful) {
                        navigate(-1);
                    }
                }}
            >
                {(props) => (
                    <Form
                        className="Form"
                        placeholder={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                    >
                        <div>
                            <label htmlFor="enabled">enabled</label>
                            <div className="left-justify">
                                <Field
                                    id="enabled"
                                    name="enabled"
                                    type="checkbox"
                                />
                            </div>
                        </div>
                        <br />
                        <div>
                            <label htmlFor="keyword">short code</label>
                            <Field
                                id="keyword"
                                name="keyword"
                                placeholder="short code"
                            />
                        </div>
                        <br />
                        <div>
                            <label htmlFor="welcomeMessage">
                                welcome message
                            </label>
                            <Field
                                id="welcomeMessage"
                                name="welcomeMessage"
                                component="textarea"
                                rows="4"
                                placeholder="Hey you, Smilebooth here! Text your photos to be added to the gallery. Txt and data rates may apply."
                            />
                        </div>
                        <br />
                        <h2>response settings</h2>
                        <div>
                            <label htmlFor="responseMessage">message</label>
                            <Field
                                id="responseMessage"
                                name="responseMessage"
                                component="textarea"
                                rows="4"
                            />
                        </div>
                        <br />
                        <div className="Settings-row">
                            <div className="Layout-row">
                                <label
                                    className="natural-width"
                                    htmlFor="sendPhoto"
                                >
                                    send photo
                                </label>
                                <Field
                                    id="sendPhoto"
                                    name="sendPhoto"
                                    type="checkbox"
                                />
                            </div>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <div className="Layout-row">
                                <label
                                    className="natural-width"
                                    htmlFor="sendPersonalGalleryLink"
                                >
                                    send personal gallery link
                                </label>
                                <Field
                                    id="sendPersonalGalleryLink"
                                    name="sendPersonalGalleryLink"
                                    type="checkbox"
                                />
                            </div>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <div className="Layout-row">
                                <label
                                    className="natural-width"
                                    htmlFor="sendGalleryLink"
                                >
                                    send gallery link
                                </label>
                                <Field
                                    id="sendGalleryLink"
                                    name="sendGalleryLink"
                                    type="checkbox"
                                />
                            </div>
                        </div>
                        <br />
                        <div className="Layout-row">
                            <Button type="submit" className="Primary-button">
                                save settings
                            </Button>
                            <Button
                                type="button"
                                onClick={() => navigate(-1)}
                                className="Secondary-button"
                            >
                                <span>cancel</span>
                            </Button>
                        </div>

                        <br />
                    </Form>
                )}
            </Formik>
        </div>
    );
}

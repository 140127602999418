import Modal from '@/src/Common/components/Modal';
import useGalleryUserManagement from '@/src/Share/components/hooks/useGalleryUserManagement';
import { Dialog } from '@headlessui/react';
import { ImagePlusIcon } from 'lucide-react';
import { useRef, useState } from 'react';

interface Props {
    onDismiss: () => void;
    galleryId: number;
}

export default function CreateUserAccountModal({
    onDismiss,
    galleryId,
}: Props) {
    const cancelButtonRef = useRef(null);
    const { createGalleryUser } = useGalleryUserManagement();
    const [isCreatingUser, setIsCreatingUser] = useState(false);
    const [userCreated, setUserCreated] = useState(false);
    const onJoin = async () => {
        if (isCreatingUser) {
            return;
        }

        // @ts-ignore
        let previousShareDestination = JSON.parse(
            // @ts-ignore
            localStorage.getItem(`sb-share-destination-${galleryId}`),
        );

        setIsCreatingUser(true);

        if (previousShareDestination) {
            await createGalleryUser(galleryId, previousShareDestination);
        }

        setIsCreatingUser(false);
        setUserCreated(true);
    };

    return (
        <Modal
            onDismiss={() => {
                onDismiss();
            }}
            cancelButtonRef={cancelButtonRef}
        >
            {userCreated ? (
                <>
                    <div>
                        <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                            <div className="sm:flex sm:items-start">
                                <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-[99999px] bg-black sm:mx-0 sm:h-10 sm:w-10">
                                    <ImagePlusIcon
                                        className="h-6 w-6 text-white"
                                        aria-hidden="true"
                                    />
                                </div>
                                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-base font-semibold leading-6 text-gray-900"
                                    >
                                        Success 🎉!
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <p className="text-sm text-gray-500">
                                            Your photos are now safe and sound
                                            📸.
                                        </p>
                                        <br />
                                        <p className="text-sm text-gray-500">
                                            We've just sent you a link to your
                                            personal gallery which you can use
                                            any time to access your photos.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                        <button
                            type="button"
                            className="mt-3 inline-flex w-full ml-4 justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                            onClick={() => onDismiss()}
                            ref={cancelButtonRef}
                        >
                            Done
                        </button>
                    </div>
                </>
            ) : (
                <>
                    <div>
                        <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                            <div className="sm:flex sm:items-start">
                                <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-[99999px] bg-black sm:mx-0 sm:h-10 sm:w-10">
                                    <ImagePlusIcon
                                        className="h-6 w-6 text-white"
                                        aria-hidden="true"
                                    />
                                </div>
                                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-base font-semibold leading-6 text-gray-900"
                                    >
                                        Don't lose your photos!
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <p className="text-sm text-gray-500">
                                            Join today to save all of the photos
                                            from this event and more in one
                                            place so you don't lose them in the
                                            depths of your camera roll.
                                        </p>
                                        <br />
                                        <p className="text-sm text-gray-500">
                                            <b>Free forever</b> and super easy
                                            to join, just click the button
                                            below.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                        <button
                            type="button"
                            className="mt-3 inline-flex w-full ml-4 justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                            onClick={() => onDismiss()}
                            ref={cancelButtonRef}
                        >
                            Cancel
                        </button>
                        <br />
                        <button
                            onClick={onJoin}
                            className="mt-3 inline-flex w-full justify-center rounded-md bg-black px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-800 hover:bg-black-50 sm:mt-0 sm:w-auto"
                            disabled={isCreatingUser}
                        >
                            {isCreatingUser ? 'Joining...' : 'Join'}
                        </button>
                        <div className="spacer"></div>
                        <div className="text-xs text-gray-500">
                            By joining you agree to our <br />
                            <a
                                href="/terms"
                                className="underline"
                                target="_top"
                            >
                                Terms of Service
                            </a>{' '}
                            and{' '}
                            <a
                                href="/privacy"
                                className="underline"
                                target="_top"
                            >
                                Privacy Policy
                            </a>
                            .
                        </div>
                    </div>
                </>
            )}
        </Modal>
    );
}

import { useEffect, useState } from 'react';
import ComboBox from './ComboBox';

type ComboboxOption = {
    label: string;
    value: string;
};

type ComboBoxOptions = ComboboxOption[];

interface MultiSelectListProps {
    options: ComboBoxOptions;
    title: string;
    values: string[];
    onChange: (value: string[]) => void;
}

export default function MultiSelectList({
    options,
    values,
    title,
    onChange,
}: MultiSelectListProps) {
    const [internalValues, setInternalValues] = useState<string[]>(values);
    const [selectedValue, setSelectedValue] = useState<string | null>(null);

    useEffect(() => {
        onChange(internalValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [internalValues]);

    return (
        <div className="flex flex-col">
            <ul className="flex flex-row">
                {internalValues.map((value) => {
                    const option = options.find(
                        (option) => option.value === value,
                    );
                    return (
                        <li
                            key={value}
                            className="flex flex-row width-row items-center rounded-[99999px] bg-gray-100 px-2 py-1 mb-1 mr-2"
                        >
                            <span className="text-sm pr-4">
                                {option?.label}
                            </span>
                            <button
                                className="btn btn-outline btn-sm"
                                onClick={() => {
                                    setInternalValues(
                                        internalValues.filter(
                                            (internalValue) =>
                                                internalValue !== value,
                                        ),
                                    );
                                }}
                            >
                                <i className="bi bi-x" />
                            </button>
                        </li>
                    );
                })}
            </ul>
            <div className="flex flex-row">
                <ComboBox
                    options={options}
                    title={title}
                    onChange={(newValue) => setSelectedValue(newValue)}
                />
                <button
                    className="btn btn-outline btn-sm"
                    onClick={(e) => {
                        e.preventDefault();
                        if (
                            selectedValue &&
                            !internalValues.includes(selectedValue)
                        ) {
                            setInternalValues([
                                ...internalValues,
                                selectedValue,
                            ]);
                        }
                    }}
                >
                    <i className="bi bi-plus" />
                </button>
            </div>
        </div>
    );
}

import {
    useNavigate,
    useParams,
    useBlocker,
    BlockerFunction,
} from 'react-router-dom';
import useExperienceBuilder from './hooks/useExperienceBuilder';
import {
    XCircleIcon,
    ExclamationTriangleIcon,
} from '@heroicons/react/20/solid';
import useExperienceDefinition from './hooks/useExperienceDefinition';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import ExperienceEditor from './ExperienceEditor';
import ExperienceDrawer from './ExperienceDrawer';
import { useDispatch, useSelector } from 'react-redux';
import ScreenEditor from './ScreenEditor';
import ScreenDesigner from './ScreenDesigner';
import UIElementEditor from './UIElementEditor';
import {
    createScreen,
    deleteScreen,
    deleteUIElement,
    resetScreens,
} from './utils/state/screensSlice';
import {
    Dialog,
    DialogPanel,
    DialogTitle,
    DialogBackdrop,
} from '@headlessui/react';
import { toast } from 'sonner';
import { ExperienceBuilderContext } from './ExperienceBuilderContext';
import { ChevronRightIcon, PlusSquareIcon, TrashIcon } from 'lucide-react';
import moment from 'moment';
import Loading from '../Common/components/Loading';
import { resetExperience } from './utils/state/experienceSlice';
import { resetProcessingFlows } from './utils/state/processingFlowSlice';

export default function ExperienceBuilder() {
    const navigate = useNavigate();

    const { activationId } = useParams();
    const [submitting, setSubmitting] = useState(false);
    const [lastSaved, setLastSaved] = useState(new Date());
    const [isLoading, setIsLoading] = useState(true);
    const {
        definition,
        screenCount,
        processingFlowCount,
        warnings,
        errors,
        importDefinition,
        exportDefinition,
        screens,
        hasUnsavedChanges,
        setLastSavedDefinition,
    } = useExperienceBuilder(activationId ? +activationId : 0);

    const {
        selectedScreenId,
        setSelectedScreenId,
        selectedElementIndex,
        setSelectedElementIndex,
    } = useContext(ExperienceBuilderContext);

    const currentScreen: ScreenDefinition | null = useSelector((state: any) => {
        if (selectedScreenId) {
            return state.screens.present.entities[selectedScreenId];
        }
        return null;
    });

    const { getDefinition, saveDefinition, deleteDefinition } =
        useExperienceDefinition();

    useEffect(() => {
        if (definition === null) {
            getDefinition(activationId ? +activationId : 0)
                .then((experience) => {
                    if (experience) {
                        importDefinition(
                            JSON.stringify(experience.definition),
                            activationId ? +activationId : 0,
                        );
                        setLastSaved(new Date());
                        setLastSavedDefinition(experience.definition);
                    }
                    setIsLoading(false);
                })
                .catch(() => {
                    setIsLoading(false);
                });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const shouldBlock = useCallback<BlockerFunction>(
        ({ currentLocation, nextLocation }: any) => {
            const shouldBlock =
                hasUnsavedChanges &&
                (currentLocation.pathname !== nextLocation.pathname ||
                    currentLocation.hash !== nextLocation.hash);

            return shouldBlock;
        },
        [hasUnsavedChanges],
    );

    const blocker = useBlocker(shouldBlock);

    const [deleteExperienceDialogOpen, setDeleteExperienceDialogOpen] =
        useState(false);

    useEffect(() => {
        const handleBeforeUnload = (e: BeforeUnloadEvent) => {
            if (hasUnsavedChanges) {
                e.preventDefault();
                e.returnValue = '';
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [hasUnsavedChanges]);

    const saveExperience = async () => {
        if (submitting) {
            return;
        }

        setSubmitting(true);

        await saveDefinition(
            activationId ? +activationId : 0,
            exportDefinition(),
        );

        setSubmitting(false);
        setLastSaved(new Date());
        setLastSavedDefinition(definition);
    };

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    const onSelectElement = (index: number | null) => {
        setSelectedElementIndex(index);
    };

    const selectedElement = useMemo(() => {
        if (selectedElementIndex === null) {
            return null;
        }
        return currentScreen?.elements[selectedElementIndex];
    }, [selectedElementIndex, currentScreen]);

    const onRemoveElement = (index: number) => {};

    const dispatch = useDispatch();

    const createBlankExperience = () => {
        importDefinition(
            JSON.stringify({
                processingFlows: [],
                screens: [],
                version: 1,
                schemaVersion: 1,
            }),
            activationId ? +activationId : 0,
        );
        dispatch(createScreen({ id: 'screen-1' }));
    };

    if (isLoading) {
        return <Loading isLoading={isLoading} />;
    }

    return (
        <div className="mb-20">
            {warnings.length > 0 ||
                (errors.length > 0 && (
                    <div className="absolute top-0 right-0 w-1/8">
                        {warnings.length > 0 && (
                            <div className="rounded-md bg-yellow-50 p-4">
                                <div className="flex">
                                    <div className="flex-shrink-0">
                                        <ExclamationTriangleIcon
                                            aria-hidden="true"
                                            className="h-5 w-5 text-yellow-400"
                                        />
                                    </div>
                                    <div className="ml-3">
                                        <h3 className="text-sm font-medium text-yellow-800">
                                            {warnings.length} warning
                                            {warnings.length > 1 ? 's' : ''} in
                                            the experience.
                                        </h3>
                                        <div className="mt-2 text-sm text-yellow-700">
                                            <ul className="list-disc space-y-1 pl-5">
                                                {warnings.map((error) => (
                                                    <li key={error}>{error}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {errors.length > 0 && (
                            <div className="rounded-md bg-red-50 p-4">
                                <div className="flex">
                                    <div className="flex-shrink-0">
                                        <XCircleIcon
                                            aria-hidden="true"
                                            className="h-5 w-5 text-red-400"
                                        />
                                    </div>
                                    <div className="ml-3">
                                        <h3 className="text-sm font-medium text-red-800">
                                            {errors.length} error
                                            {errors.length > 1 ? 's' : ''} in
                                            the experience.
                                        </h3>
                                        <div className="mt-2 text-sm text-red-700">
                                            <ul className="list-disc space-y-1 pl-5">
                                                {errors.map((error) => (
                                                    <li key={error}>{error}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                ))}
            <div className="">
                {definition === null || screens.length === 0 ? (
                    <div className="ml-8 max-w-lg mt-10 justify-start">
                        <h2 className="text-base font-semibold leading-6 text-gray-900">
                            Create Your Experience
                        </h2>
                        <p className="mt-1 text-sm text-gray-500">
                            Get started by selecting a template or start from an
                            empty experience.
                        </p>
                        <ul className="mt-6 divide-y divide-gray-200 border-b border-t border-gray-200">
                            {[
                                /* {
                                    name: 'Clicker',
                                    description:
                                        'A simple clicker experience with on screen sharing.',
                                    iconColor: 'bg-blue-500',
                                    icon: PlusSquareIcon,
                                    href: '#',
                                },
                                {
                                    name: 'GIF',
                                    description:
                                        'A GIF experience with on screen sharing.',
                                    iconColor: 'bg-green-500',
                                    icon: PlusSquareIcon,
                                    href: '#',
                                },
                                {
                                    name: 'Sequence',
                                    description:
                                        'A sequence experience with on screen sharing and printing.',
                                    iconColor: 'bg-red-500',
                                    icon: PlusSquareIcon,
                                    href: '#',
                                },*/
                            ].map((item: any, itemIdx) => (
                                <li key={itemIdx}>
                                    <div className="group relative flex items-start space-x-3 py-4">
                                        <div className="flex-shrink-0">
                                            <span
                                                className={classNames(
                                                    item.iconColor,
                                                    'inline-flex h-10 w-10 items-center justify-center rounded-lg',
                                                )}
                                            >
                                                <item.icon
                                                    aria-hidden="true"
                                                    className="h-6 w-6 text-white"
                                                />
                                            </span>
                                        </div>
                                        <div className="min-w-0 flex-1">
                                            <div className="text-sm font-medium text-gray-900">
                                                <a href={item.href}>
                                                    <span
                                                        aria-hidden="true"
                                                        className="absolute inset-0"
                                                    />
                                                    {item.name}
                                                </a>
                                            </div>
                                            <p className="text-sm text-gray-500">
                                                {item.description}
                                            </p>
                                        </div>
                                        <div className="flex-shrink-0 self-center">
                                            <ChevronRightIcon
                                                aria-hidden="true"
                                                className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                            />
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                        <div className="mt-6 flex">
                            <span
                                className="text-sm font-medium text-indigo-600 hover:text-indigo-500 cursor-pointer"
                                onClick={createBlankExperience}
                            >
                                Or start from scratch
                                <span aria-hidden="true"> &rarr;</span>
                            </span>
                        </div>
                    </div>
                ) : (
                    <ExperienceEditor
                        screens={screens}
                        onScreenSelected={(screenId) => {
                            console.log('Screen selected', screenId);
                            setSelectedScreenId(screenId);
                        }}
                        onDefinitionChange={(definition) => {}}
                    />
                )}
            </div>
            <div className="fixed border border-gray-300 border-solid bottom-[84px] left-2 bg-white rounded-lg flex-col p-2 gap-3 grid z-100">
                <button
                    className={`flex items-center gap-2 cursor-pointer`}
                    onClick={() => {
                        const newScreen = {
                            id: `screen-${Math.random()
                                .toString(36)
                                .substring(2, 8)}`,
                            elements: [],
                        };
                        dispatch(createScreen(newScreen));
                        setSelectedScreenId(newScreen.id);
                    }}
                    title="Add Screen"
                >
                    <PlusSquareIcon />
                </button>
                <button
                    className="h-6 w-6 hover:text-red-500 cursor-pointer"
                    onClick={() => {
                        setDeleteExperienceDialogOpen(true);
                    }}
                    title="Delete Experience"
                >
                    <TrashIcon />
                </button>
            </div>
            <div className="">
                <div className="mt-6 flex items-center justify-end gap-x-6 px-6 fixed bottom-0 left-0 right-0 z-100 border-t border-gray-900/10 bg-white p-3">
                    <button
                        className="rounded-md bg-black px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 transition-all focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black  disabled:opacity-50"
                        onClick={() => {
                            const fileName = 'experience-definition';
                            const json = exportDefinition();
                            const blob = new Blob([json], {
                                type: 'application/json',
                            });
                            const href = URL.createObjectURL(blob);

                            const link = document.createElement('a');
                            link.href = href;
                            link.download = fileName + '.json';
                            document.body.appendChild(link);
                            link.click();

                            // clean up "a" element & remove ObjectURL
                            document.body.removeChild(link);
                            URL.revokeObjectURL(href);
                            toast.success('Experience definition exported.');
                        }}
                        disabled={!definition}
                    >
                        Export
                    </button>
                    <button
                        className="rounded-md bg-black px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 transition-all focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
                        onClick={() => {
                            const input = document.createElement('input');
                            input.type = 'file';
                            input.accept = '.json';
                            input.onchange = (e) => {
                                const file = (e.target as HTMLInputElement)
                                    .files
                                    ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                      //@ts-ignore
                                      (e.target as HTMLInputElement).files[0]
                                    : null;
                                if (file) {
                                    const reader = new FileReader();
                                    reader.onload = (e) => {
                                        const text = e.target?.result;
                                        if (typeof text === 'string') {
                                            importDefinition(
                                                text,
                                                activationId
                                                    ? +activationId
                                                    : 0,
                                            );
                                            saveExperience();

                                            toast.success(
                                                'Experience definition imported.',
                                            );
                                        }
                                    };
                                    reader.readAsText(file);
                                }
                            };
                            input.click();
                        }}
                    >
                        Import
                    </button>
                    <div className="spacer" />
                    {definition && (
                        <div className="flex flex-col items-center gap-2">
                            <div className="flex flex-row gap-2">
                                <div className="Form-row">
                                    <label
                                        htmlFor="screenCount"
                                        className="text-sm font-semibold text-gray-900"
                                    >
                                        Screens:
                                    </label>
                                    <span className="text-sm font-semibold text-gray-900">
                                        {screenCount}
                                    </span>
                                </div>
                                <div className="Form-row">
                                    <label
                                        htmlFor="processingFlowCount"
                                        className="text-sm font-semibold text-gray-900"
                                    >
                                        Flows:
                                    </label>
                                    <span className="text-sm font-semibold text-gray-900">
                                        {processingFlowCount}
                                    </span>
                                </div>
                            </div>
                            <div className="text-sm text-gray-500">
                                Last saved {moment(lastSaved).fromNow()}
                            </div>
                        </div>
                    )}
                    <div className="spacer" />

                    <button
                        className="text-sm font-semibold leading-6 text-gray-900"
                        type="button"
                        onClick={() => navigate(-1)}
                    >
                        <span>Cancel</span>
                    </button>
                    <button
                        className="rounded-md bg-black px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 transition-all focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black disabled:opacity-50 transition-all"
                        type="submit"
                        onClick={saveExperience}
                        disabled={!hasUnsavedChanges}
                    >
                        Save
                    </button>
                </div>
            </div>

            <ExperienceDrawer
                isOpen={!!selectedScreenId}
                onClose={() => {
                    setSelectedScreenId(null);
                    setSelectedElementIndex(null);
                }}
                title={
                    `${selectedElement ? 'Edit Element' : 'Edit Screen'}: ${
                        selectedElement?.id || currentScreen?.id
                    }` || ''
                }
                body={
                    currentScreen && (
                        <ScreenDesigner
                            currentScreen={currentScreen}
                            onSelectElement={onSelectElement}
                            onRemoveElement={onRemoveElement}
                        />
                    )
                }
                onDelete={() => {
                    setDeleteDialogOpen(true);
                }}
            >
                {selectedElement ? (
                    <UIElementEditor
                        element={selectedElement}
                        screen={currentScreen}
                        onIdChange={(newId: string) =>
                            setSelectedScreenId(newId)
                        }
                    />
                ) : (
                    <ScreenEditor
                        screen={currentScreen}
                        onIdChange={(newId: string) =>
                            setSelectedScreenId(newId)
                        }
                    />
                )}
            </ExperienceDrawer>
            <Dialog
                open={deleteExperienceDialogOpen}
                onClose={() => setDeleteExperienceDialogOpen(false)}
                className="relative z-20"
            >
                <DialogBackdrop
                    transition
                    className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                />

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <DialogPanel
                            transition
                            className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                        >
                            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                <div className="sm:flex sm:items-start">
                                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-[99999px] bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                        <ExclamationTriangleIcon
                                            aria-hidden="true"
                                            className="h-6 w-6 text-red-600"
                                        />
                                    </div>
                                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                        <DialogTitle
                                            as="h3"
                                            className="text-base font-semibold leading-6 text-gray-900"
                                        >
                                            Delete Entire Experience
                                        </DialogTitle>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                                Are you sure you want to delete
                                                the entire experience? This
                                                action cannot be undone and will
                                                remove all screens and elements.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                <button
                                    type="button"
                                    className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                                    onClick={async () => {
                                        if (activationId) {
                                            await deleteDefinition(
                                                activationId
                                                    ? +activationId
                                                    : 0,
                                            );
                                        }
                                        dispatch(resetScreens());
                                        dispatch(resetProcessingFlows());
                                        dispatch(resetExperience());

                                        setDeleteExperienceDialogOpen(false);
                                    }}
                                >
                                    Delete
                                </button>
                                <button
                                    type="button"
                                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                    onClick={() =>
                                        setDeleteExperienceDialogOpen(false)
                                    }
                                >
                                    Cancel
                                </button>
                            </div>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>
            <Dialog
                open={deleteDialogOpen}
                onClose={setDeleteDialogOpen}
                className="relative z-20"
            >
                <DialogBackdrop
                    transition
                    className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                />

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <DialogPanel
                            transition
                            className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                        >
                            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                <div className="sm:flex sm:items-start">
                                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-[99999px] bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                        <ExclamationTriangleIcon
                                            aria-hidden="true"
                                            className="h-6 w-6 text-red-600"
                                        />
                                    </div>
                                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                        <DialogTitle
                                            as="h3"
                                            className="text-base font-semibold leading-6 text-gray-900"
                                        >
                                            Delete{' '}
                                            {selectedElement
                                                ? 'Element'
                                                : 'Screen'}
                                        </DialogTitle>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                                Are you sure you want to delete
                                                this{' '}
                                                {selectedElement
                                                    ? 'element'
                                                    : 'screen'}
                                                ? This action cannot be undone.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                <button
                                    type="button"
                                    onClick={() => {
                                        setDeleteDialogOpen(false);
                                        if (selectedElement) {
                                            dispatch(
                                                deleteUIElement({
                                                    elementId:
                                                        selectedElement.id,
                                                    screenId: currentScreen?.id,
                                                }),
                                            );
                                            setSelectedElementIndex(null);
                                        } else if (currentScreen) {
                                            dispatch(
                                                deleteScreen({
                                                    screenId: currentScreen.id,
                                                }),
                                            );
                                            setSelectedScreenId(null);
                                        }
                                        toast.success(
                                            `Deleted ${
                                                selectedElement
                                                    ? 'element'
                                                    : 'screen'
                                            }`,
                                        );
                                    }}
                                    className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                                >
                                    Delete
                                </button>
                                <button
                                    type="button"
                                    data-autofocus
                                    onClick={() => setDeleteDialogOpen(false)}
                                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                >
                                    Cancel
                                </button>
                            </div>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>

            <Dialog
                open={blocker?.state === 'blocked'}
                onClose={() => blocker?.reset?.()}
                className="relative z-20"
            >
                <DialogBackdrop
                    transition
                    className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                />

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <DialogPanel
                            transition
                            className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                        >
                            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                <div className="sm:flex sm:items-start">
                                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-[99999px] bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                        <ExclamationTriangleIcon
                                            aria-hidden="true"
                                            className="h-6 w-6 text-red-600"
                                        />
                                    </div>
                                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                        <DialogTitle
                                            as="h3"
                                            className="text-base font-semibold leading-6 text-gray-900"
                                        >
                                            Discard unsaved changes?
                                        </DialogTitle>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                                You have unsaved changes.
                                                <br />
                                                <br />
                                                Are you sure you want to discard
                                                them?
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                <button
                                    type="button"
                                    onClick={() => {
                                        blocker?.proceed?.();
                                    }}
                                    className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                                >
                                    Yes, discard changes
                                </button>
                                <button
                                    type="button"
                                    data-autofocus
                                    onClick={() => {
                                        blocker?.reset?.();
                                    }}
                                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                >
                                    Cancel
                                </button>
                            </div>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>
        </div>
    );
}

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ');
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import { Fragment, useEffect, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import ActivationsList from './ActivationsList';
import useJobHelpers from './hooks/useJobHelpers';
import Loading from '@/src/Common/components/Loading';
import { useParams } from 'react-router-dom';
import useAccounts from '@/src/Accounts/components/hooks/useAccounts';
import { format } from 'date-fns';

function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(' ');
}

export default function JobDetail() {
    //const [selected, setSelected] = useState(moods[2]);
    const [job, setJob] = useState<any>(null);

    const [isLoading, setIsLoading] = useState(true);
    const { getJob } = useJobHelpers();
    const { getAccounts } = useAccounts();
    const { id } = useParams();

    useEffect(() => {
        let isMounted = true;
        const getData = async () => {
            try {
                const users = await getAccounts();
                // @ts-ignore
                const job = await getJob(+id || 0);

                const jobOwner = users.find(
                    (user: any) => user.id === job.ownerId,
                );

                const jobClients = users.filter((user: any) => {
                    return job.clientIds.includes(user.id);
                });
                setJob({
                    ...job,
                    ownerName: `${jobOwner.firstName} ${jobOwner.lastName}`,
                    clients: jobClients,
                });

                setTimeout(() => isMounted && setIsLoading(false), 0);
            } catch (error) {
                console.error(error);
            }
        };

        getData();

        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLoading) {
        return <Loading isLoading={true} />;
    }

    if (!job) {
        return <div>Job not found</div>;
    }

    return (
        <main>
            <header className="relative isolate pt-0">
                <div
                    className="absolute inset-0 -z-10 overflow-hidden"
                    aria-hidden="true"
                >
                    <div className="absolute left-16 top-full -mt-16 transform-gpu opacity-50 blur-3xl xl:left-1/2 xl:-ml-80"></div>
                    <div className="absolute inset-x-0 bottom-0 h-px bg-gray-900/5" />
                </div>

                <div className="mx-auto max-w-12xl px-4 py-8 sm:px-6 lg:px-8">
                    <div className="mx-auto flex max-w-2xl items-center justify-between gap-x-8 lg:mx-0 lg:max-w-none">
                        <div className="flex items-center gap-x-6">
                            <h1>
                                <div className="text-sm leading-6 text-gray-500">
                                    <span className="text-gray-700">
                                        {job.client}
                                    </span>
                                </div>
                                <div className="mt-1 text-2xl font-semibold leading-6 text-gray-900">
                                    {job.name}
                                </div>
                                <div className="mt-1 text-sm font-thin leading-6 text-gray-900">
                                    Lead:{' '}
                                    <span className="font-semibold capitalize">
                                        {job.ownerName}
                                    </span>
                                </div>

                                <div className="mt-1 text-sm font-thin leading-6 text-gray-900">
                                    <div className="flex items-center gap-x-1">
                                        Dates:{' '}
                                        <span className="">
                                            {format(
                                                job.startDate,
                                                'MM/dd/yyyy',
                                            )}{' '}
                                            {job.endDate &&
                                                '- ' +
                                                    format(
                                                        job.endDate,
                                                        'MM/dd/yyyy',
                                                    )}
                                        </span>
                                    </div>
                                </div>
                                <div className="mt-1 text-sm font-thin leading-6 text-gray-900">
                                    <div className="flex items-center gap-x-1 align-start">
                                        Clients:{' '}
                                        <span className="">
                                            {job.clients.map((client: any) => (
                                                <span className="text-sm font-regular roboto leading-6 text-gray-900 mr-2 capitalize rounded-[99999px] bg-gray-100 px-2 py-1 mb-1 mr-2 ">
                                                    {client.firstName}{' '}
                                                    {client.lastName}
                                                </span>
                                            ))}
                                        </span>
                                    </div>
                                </div>
                            </h1>
                        </div>

                        <div className="flex items-center gap-x-4 sm:gap-x-6">
                            <a
                                href={`${job.id}/edit`}
                                type="button"
                                className="hidden text-sm font-semibold leading-6 text-gray-900 sm:block"
                            >
                                Edit Details
                            </a>

                            <Menu as="div" className="relative sm:hidden">
                                <Menu.Button className="-m-3 block p-3">
                                    <span className="sr-only">More</span>
                                    <EllipsisVerticalIcon
                                        className="h-5 w-5 text-gray-500"
                                        aria-hidden="true"
                                    />
                                </Menu.Button>

                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                        <Menu.Item>
                                            {({ active }) => (
                                                <a
                                                    href="#"
                                                    className={classNames(
                                                        active
                                                            ? 'bg-gray-50'
                                                            : '',
                                                        'block px-3 py-1 text-sm leading-6 text-gray-900',
                                                    )}
                                                >
                                                    Edit Details
                                                </a>
                                            )}
                                        </Menu.Item>
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                        </div>
                    </div>
                </div>
                <div className="text-sm pb-1 mt-1 mx-4 sm:mx-8 font-thin leading-6 text-gray-900 border-b border-gray-200">
                    <span className="uppercase">Description</span>
                </div>
                <div className="text-sm font-light text-[14px] px-4 sm:px-8 pb-8 roboto">
                    {job.description}
                </div>
            </header>

            <ActivationsList jobId={job.id} />
        </main>
    );
}

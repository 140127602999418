import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input } from '@headlessui/react';
import DialogFormRow from './DialogFormRow';
import { ColorPicker } from './ColorPicker';
import { updateScreenUIElement } from './utils/state/screensSlice';
import { updateScreenUIElementMetadata } from './utils/state/screensSlice';
import { ArrowRightIcon } from '@radix-ui/react-icons';
import { ExperienceBuilderContext } from './ExperienceBuilderContext';
import { CheckSquare, Square } from 'lucide-react';

interface UIElementPropertiesFormProps {
    element: UIElement;
    screen: ScreenDefinition;
}

export default function UIElementPropertiesForm({
    element,
    screen,
}: UIElementPropertiesFormProps) {
    const dispatch = useDispatch();
    console.log('element', element);
    console.log('screen', screen);

    const screenNames: [string] = useSelector((state: any) => {
        return state.screens.present.ids.map(
            (id: string) => state.screens.present.entities[id].id,
        );
    });

    const {
        setSelectedScreenId,
        setSelectedElementIndex,
        bulkCopyMode,
        bulkCopyProperties,
        setBulkCopyProperties,
    } = useContext(ExperienceBuilderContext);

    const handleChange = (name: string, value: any) => {
        dispatch(
            updateScreenUIElement({
                screenId: screen.id,
                key: name,
                value: value,
                elementId: element.id,
            }),
        );
    };

    const handleMetadataChange = (name: string, value: any) => {
        dispatch(
            updateScreenUIElementMetadata({
                screenId: screen.id,
                elementId: element.id,
                key: name,
                value: value,
            }),
        );
    };

    const bulkCopyCheckbox = (property: string) => (
        <>
            <div className="flex-1" />
            <div
                className=""
                onClick={() => {
                    if (bulkCopyProperties.includes(property)) {
                        setBulkCopyProperties(
                            bulkCopyProperties.filter(
                                (bulkProperty) => bulkProperty !== property,
                            ),
                        );
                    } else {
                        setBulkCopyProperties([
                            ...bulkCopyProperties,
                            property,
                        ]);
                    }
                }}
            >
                {bulkCopyProperties.includes(property) ? (
                    <CheckSquare />
                ) : (
                    <Square />
                )}
            </div>
        </>
    );

    const renderField = (key: string, value: any) => {
        if (key === 'order') {
            return null;
        }

        if (
            typeof value === 'string' ||
            typeof value === 'number' ||
            value === null
        ) {
            if (key.toLowerCase().includes('color')) {
                return (
                    <DialogFormRow key={key} title={key} field={key}>
                        <div className="flex items-center gap-2">
                            <ColorPicker
                                name={key}
                                value={value as string}
                                onChange={(value) => {
                                    handleChange(key, value);
                                }}
                            />
                            <span className="text-sm text-black-500">
                                {value}
                            </span>
                            {bulkCopyMode && bulkCopyCheckbox(key)}
                        </div>
                    </DialogFormRow>
                );
            }

            if (key === 'verticalLocation') {
                return (
                    <DialogFormRow key={key} title={key} field={key}>
                        <div className="flex items-center gap-2">
                            <select
                                name={key}
                                value={value}
                                className="block w-full rounded-md border-0 py-1.5 pl-3 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                onChange={(
                                    e: React.ChangeEvent<HTMLSelectElement>,
                                ) => {
                                    handleChange(key, e.target.value);
                                }}
                            >
                                <option value="top">Top</option>
                                <option value="center">Center</option>
                                <option value="bottom">Bottom</option>
                            </select>
                        </div>
                    </DialogFormRow>
                );
            }

            if (key === 'horizontalLocation') {
                return (
                    <DialogFormRow key={key} title={key} field={key}>
                        <div className="flex items-center gap-2">
                            <select
                                name={key}
                                value={value}
                                className="block w-full rounded-md border-0 py-1.5 pl-3 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                onChange={(
                                    e: React.ChangeEvent<HTMLSelectElement>,
                                ) => {
                                    handleChange(key, e.target.value);
                                }}
                            >
                                <option value="leading">Left</option>
                                <option value="center">Center</option>
                                <option value="trailing">Right</option>
                            </select>
                        </div>
                    </DialogFormRow>
                );
            }

            return (
                <DialogFormRow key={key} title={key} field={key}>
                    <div className="flex items-center gap-2">
                        <Input
                            type={typeof value === 'number' ? 'number' : 'text'}
                            name={key}
                            value={value}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                                if (typeof value === 'number') {
                                    handleChange(key, parseInt(e.target.value));
                                } else {
                                    console.log('value', value);
                                    handleChange(key, e.target.value);
                                }
                            }}
                            className="block w-full rounded-md border-0 py-1.5 pl-3 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        {bulkCopyMode && bulkCopyCheckbox(key)}
                    </div>
                </DialogFormRow>
            );
        }
        return null;
    };

    const renderMetadataField = (key: string, value: any) => {
        if (
            typeof value === 'string' ||
            typeof value === 'number' ||
            value === null
        ) {
            if (key.toLowerCase().includes('color')) {
                return (
                    <DialogFormRow key={key} title={key} field={key}>
                        <div className="flex items-center gap-2">
                            <ColorPicker
                                name={key}
                                value={value as string}
                                onChange={(value) => {
                                    handleMetadataChange(key, value);
                                }}
                            />
                            <span className="text-sm text-black-500">
                                {value}
                            </span>
                            {bulkCopyMode && bulkCopyCheckbox(key)}
                        </div>
                    </DialogFormRow>
                );
            }

            if (key === 'destination') {
                return (
                    <DialogFormRow key={key} title={key} field={key}>
                        <div className="flex items-center gap-2">
                            <select
                                name={key}
                                value={value}
                                className="block w-full rounded-md border-0 py-1.5 pl-3 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                onChange={(
                                    e: React.ChangeEvent<HTMLSelectElement>,
                                ) => {
                                    handleMetadataChange(key, e.target.value);
                                }}
                            >
                                {screenNames.map((screen) => (
                                    <option value={screen}>{screen}</option>
                                ))}
                            </select>
                            <ArrowRightIcon
                                className="w-5 h-5 border border-black rounded-[99999px] p-1 hover:bg-black hover:text-white transition-all duration-300 cursor-pointer"
                                onClick={() => {
                                    setSelectedScreenId(value);
                                    setSelectedElementIndex(null);
                                }}
                            />
                            {bulkCopyMode && bulkCopyCheckbox(key)}
                        </div>
                    </DialogFormRow>
                );
            }

            if (key === 'action') {
                return (
                    <DialogFormRow key={key} title={key} field={key}>
                        <div className="flex items-center gap-2">
                            <select
                                name={key}
                                value={value}
                                className="block w-full rounded-md border-0 py-1.5 pl-3 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                onChange={(
                                    e: React.ChangeEvent<HTMLSelectElement>,
                                ) => {
                                    handleMetadataChange(key, e.target.value);
                                }}
                            >
                                <option value="navigate">Navigate</option>
                                <option value="set-value">Set Value</option>
                                <option value="reset">Reset</option>
                                <option value="share">Share</option>
                                <option value="print">Print</option>
                            </select>
                            {bulkCopyMode && bulkCopyCheckbox(key)}
                        </div>
                    </DialogFormRow>
                );
            }

            if (key === 'inputType') {
                return (
                    <DialogFormRow key={key} title={key} field={key}>
                        <div className="flex items-center gap-2">
                            <select
                                name={key}
                                value={value}
                                className="block w-full rounded-md border-0 py-1.5 pl-3 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                onChange={(
                                    e: React.ChangeEvent<HTMLSelectElement>,
                                ) => {
                                    handleMetadataChange(key, e.target.value);
                                }}
                            >
                                <option value="text">Text</option>
                                <option value="number">Number</option>
                                <option value="email">Email</option>
                                <option value="phone">Phone</option>
                                <option value="bool">Checkbox</option>
                            </select>
                            {bulkCopyMode && bulkCopyCheckbox(key)}
                        </div>
                    </DialogFormRow>
                );
            }

            if (key === 'scene') {
                return (
                    <DialogFormRow key={key} title={'Source'} field={key}>
                        <div className="flex items-center gap-2">
                            <select
                                name={key}
                                value={value}
                                className="block w-full rounded-md border-0 py-1.5 pl-3 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                onChange={(
                                    e: React.ChangeEvent<HTMLSelectElement>,
                                ) => {
                                    handleMetadataChange(key, e.target.value);
                                }}
                            >
                                <option value="">Last Taken</option>
                                {screenNames.map((screen) => (
                                    <option value={screen}>{screen}</option>
                                ))}
                            </select>
                            {bulkCopyMode && bulkCopyCheckbox(key)}
                        </div>
                    </DialogFormRow>
                );
            }

            if (key === 'fontWeight') {
                return (
                    <DialogFormRow key={key} title={key} field={key}>
                        <div className="flex items-center gap-2">
                            <select
                                name={key}
                                value={value.toString()}
                                className="block w-full rounded-md border-0 py-1.5 pl-3 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                onChange={(
                                    e: React.ChangeEvent<HTMLSelectElement>,
                                ) => {
                                    handleMetadataChange(
                                        key,
                                        parseInt(e.target.value),
                                    );
                                }}
                            >
                                <option value="100">100</option>
                                <option value="200">200</option>
                                <option value="300">300</option>
                                <option value="400">400</option>
                                <option value="500">500</option>
                                <option value="600">600</option>
                                <option value="700">700</option>
                                <option value="800">800</option>
                                <option value="900">900</option>
                            </select>
                            {bulkCopyMode && bulkCopyCheckbox(key)}
                        </div>
                    </DialogFormRow>
                );
            }
            return (
                <DialogFormRow key={key} title={key} field={key}>
                    <div className="flex items-center gap-2">
                        <Input
                            type={typeof value === 'number' ? 'number' : 'text'}
                            name={key}
                            value={value}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                                if (typeof value === 'number') {
                                    handleMetadataChange(
                                        key,
                                        parseInt(e.target.value),
                                    );
                                } else {
                                    console.log('value', value);
                                    handleMetadataChange(key, e.target.value);
                                }
                            }}
                            className="block w-full rounded-md border-0 py-1.5 pl-3 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        {bulkCopyMode && bulkCopyCheckbox(key)}
                    </div>
                </DialogFormRow>
            );
        }
        return null;
    };

    return (
        <>
            {Object.entries(element).map(([key, value]) => {
                if (key !== 'id' && key !== 'type') {
                    return renderField(key, value);
                }
                return null;
            })}
            {Object.entries(element.metadata).map(([key, value]) => {
                if (key !== 'id' && key !== 'type') {
                    return renderMetadataField(key, value);
                }
                return null;
            })}
        </>
    );
}

import { Button } from '@/src/shadComponents/ui/button';
import { Card, CardContent, CardHeader } from '@/src/shadComponents/ui/card';
import { Input } from '@/src/shadComponents/ui/input';
import { Label } from '@/src/shadComponents/ui/label';

interface DeepLocalSettingsModalProps {
    deepLocalIPAddress: string;
    deepLocalPort: string;
    setDeepLocalIPAddress: (ipAddress: string) => void;
    setDeepLocalPort: (port: string) => void;
    setShowModal: (show: boolean) => void;
    deepLocalEndpoint: string;
    setDeepLocalEndpoint: (endpoint: string) => void;
}

export default function DeepLocalSettingsModal({
    deepLocalIPAddress,
    deepLocalPort,
    setDeepLocalIPAddress,
    setDeepLocalPort,
    setShowModal,
    deepLocalEndpoint,
    setDeepLocalEndpoint,
}: DeepLocalSettingsModalProps) {
    return (
        <div className="Fullscreen-modal flex justify-center">
            <div className="flex flex-row justify-center content-center align-center h-screen">
                <div className="spacer"></div>
                <Card className="m-auto flex-col justify-center content-center">
                    <CardHeader>
                        <h2>Deep Local Settings</h2>
                    </CardHeader>
                    <CardContent>
                        <div className="flex justify-between">
                            <div>
                                <Label htmlFor="deepLocalIPAddress">
                                    IP Address
                                </Label>
                                <Input
                                    type="text"
                                    id="deepLocalIPAddress"
                                    value={deepLocalIPAddress}
                                    onChange={(e) => {
                                        setDeepLocalIPAddress(e.target.value);
                                    }}
                                ></Input>
                            </div>
                        </div>
                        <br />
                        <div className="flex justify-between">
                            <div>
                                <Label htmlFor="deepLocalPort">Port</Label>
                                <Input
                                    type="number"
                                    id="deepLocalPort"
                                    value={deepLocalPort}
                                    onChange={(e) => {
                                        setDeepLocalPort(e.target.value);
                                    }}
                                ></Input>
                            </div>
                        </div>
                        <br />
                        <div className="flex justify-between">
                            <div>
                                <Label htmlFor="deepLocalEndpoint">
                                    Endpoint
                                </Label>
                                <br />
                                <select
                                    value={deepLocalEndpoint}
                                    onChange={(e) => {
                                        setDeepLocalEndpoint(e.target.value);
                                    }}
                                >
                                    <option value="/api/upload/url">
                                        Covershot
                                    </option>
                                    <option value="/api/uploadDunkPhoto/url">
                                        Overhead
                                    </option>
                                </select>
                            </div>
                        </div>
                        <br />
                        <div className="flex justify-end">
                            <Button
                                type="button"
                                className="Primary-button"
                                onClick={() => setShowModal(false)}
                            >
                                Close
                            </Button>
                        </div>
                    </CardContent>
                </Card>
                <div className="spacer"></div>
            </div>
        </div>
    );
}

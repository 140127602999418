import { DatePicker } from '@/src/shadComponents/ui/DatePicker';
import { Dialog, Transition } from '@headlessui/react';
import { DocumentPlusIcon, PlusIcon } from '@heroicons/react/20/solid';
import { Fragment, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import LocationSmartField from '@/src/Common/components/LocationSmartField';

interface CreateActivationModalProps {
    onDismiss: (id?: number) => void;
}

export default function CreateActivationModal({
    onDismiss,
}: CreateActivationModalProps) {
    const cancelButtonRef = useRef(null);
    const [newName, setNewName] = useState('');
    const [newDate, setNewDate] = useState(new Date());
    const [newLocation, setNewLocation] = useState('');
    //const [createFromTemplate, setCreateFromTemplate] = useState(false);
    const [templateId, setTemplateId] = useState<number | null>(null);

    return (
        <Transition.Root show={true} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-10"
                initialFocus={cancelButtonRef}
                onClose={() => onDismiss()}
                open={true}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                {!templateId ? (
                                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                        <div className="sm:flex sm:items-start">
                                            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-[99999px] bg-black sm:mx-0 sm:h-10 sm:w-10">
                                                <DocumentPlusIcon
                                                    className="h-6 w-6 text-white"
                                                    aria-hidden="true"
                                                />
                                            </div>
                                            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                                <Dialog.Title
                                                    as="h3"
                                                    className="text-base font-semibold leading-6 text-gray-900"
                                                >
                                                    Create Activation
                                                </Dialog.Title>
                                                <div className="mt-2">
                                                    <p className="text-sm text-gray-500">
                                                        Create activation from
                                                        template, or create a
                                                        new activation from
                                                        scratch.
                                                    </p>
                                                </div>
                                            </div>
                                            <div></div>
                                        </div>
                                        <div className="ml-12 mt-2 p-2">
                                            <div className="grid grid-cols-2 gap-4">
                                                <NavLink to="create">
                                                    <div className="bg-gray-100 p-4 align-center justify-center ring-1 ring-inset ring-gray-50 hover:ring-gray-200 cursor-pointer">
                                                        <h4 className="text-lg font-semibold mb-4 align-self-center center">
                                                            From Scratch
                                                        </h4>

                                                        <PlusIcon className="h-6 w-6 align-center ml-auto mr-auto" />
                                                    </div>
                                                </NavLink>
                                                <div className=" p-2">
                                                    <button
                                                        onClick={() =>
                                                            setTemplateId(1)
                                                        }
                                                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-0 ring-inset ring-gray-50 hover:bg-gray-50 sm:mt-0 sm:w-auto mb-3  cursor-pointer"
                                                    >
                                                        ROMR
                                                        Template&nbsp;&rarr;
                                                    </button>
                                                    <button
                                                        onClick={() =>
                                                            setTemplateId(2)
                                                        }
                                                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-0 ring-inset ring-gray-50 hover:bg-gray-50 sm:mt-0 sm:w-auto  cursor-pointer"
                                                    >
                                                        SB&nbsp;Default&nbsp;Template&nbsp;&rarr;
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : null}
                                {templateId ? (
                                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                        <div className="sm:flex sm:items-start">
                                            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-[99999px] bg-black sm:mx-0 sm:h-10 sm:w-10">
                                                <DocumentPlusIcon
                                                    className="h-6 w-6 text-white"
                                                    aria-hidden="true"
                                                />
                                            </div>
                                            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                                <Dialog.Title
                                                    as="h3"
                                                    className="text-base font-semibold leading-6 text-gray-900"
                                                >
                                                    Create Activation
                                                </Dialog.Title>
                                                <div className="mt-2">
                                                    <p className="text-sm text-gray-500">
                                                        Please provide a name
                                                        and date for the new
                                                        activation.
                                                    </p>
                                                </div>
                                            </div>
                                            <div></div>
                                        </div>
                                        <div className="ml-12 mt-2 p-2">
                                            <div>
                                                <label
                                                    htmlFor="name"
                                                    className="block text-sm font-medium leading-6 text-gray-900"
                                                >
                                                    Activation Name
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        id="name"
                                                        name="name"
                                                        type="text"
                                                        required
                                                        value={newName}
                                                        onChange={(e) =>
                                                            setNewName(
                                                                e.target.value,
                                                            )
                                                        }
                                                        className="block w-full p-2 rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-span-full pt-2">
                                                <label
                                                    htmlFor="date"
                                                    className="block text-sm font-medium leading-6 text-gray-900"
                                                >
                                                    Date
                                                </label>
                                                <DatePicker
                                                    id="date"
                                                    date={newDate}
                                                    setDate={setNewDate}
                                                />
                                            </div>
                                            <div className="col-span-full pt-2">
                                                <label
                                                    htmlFor="location"
                                                    className="block text-sm font-medium leading-6 text-gray-900"
                                                >
                                                    Location
                                                </label>
                                                <LocationSmartField
                                                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                    name="location"
                                                    value={newLocation}
                                                    setValue={(
                                                        newValue: string,
                                                    ) =>
                                                        setNewLocation(newValue)
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ) : null}
                                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                    <button
                                        type="button"
                                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                        onClick={() => onDismiss()}
                                        ref={cancelButtonRef}
                                    >
                                        Cancel
                                    </button>
                                    {templateId ? (
                                        <button
                                            type="button"
                                            className="mt-3 inline-flex w-full justify-center transitions-all rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto mr-6"
                                            onClick={() => setTemplateId(null)}
                                        >
                                            Back
                                        </button>
                                    ) : null}
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useGallery from '../hooks/useGallery';
import Loading from '@/src/Common/components/Loading';
import GlobalStyle from '@/src/Share/components/GalleryTheme';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import logo from '../white_logo.png';
import useActivationPayments from './hooks/useActivationPaymentInfo';
import PaymentForm from './PaymentForm';

const stripePromise = loadStripe(
    window.location.href.includes('.alpha.')
        ? 'pk_test_LiQ8AvUyDPBaUPp5nOu25x2u'
        : 'pk_live_mzdaENBcs2NCPvE9bGFmYtrt',
);

export default function PaymentLanding() {
    const [isLoading, setIsLoading] = useState(true);
    const { token, slug } = useParams();
    const [galleryInfo, setGalleryInfo] = useState<GalleryObject | null>(null);
    const [, setGalleryPaymentSettings] = useState<any | null>(null);
    const [options, setOptions] = useState<any | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [isPaymentInvalidated, setIsPaymentInvalidated] = useState(false);
    const [isPaymentConfirmed, setIsPaymentConfirmed] = useState(false);
    const [paymentIntent, setPaymentIntent] = useState<any | null>(null);
    const [sessionPayment, setSessionPayment] = useState<any | null>(null);

    const { getGallery } = useGallery({
        slug: slug ?? '',
        galleryId: 0,
    });

    const {
        getActivationPaymentSettings,
        markPaymentAsPaid,
        markTokenAsClaimed,
    } = useActivationPayments();

    const { createPaymentIntent } = useActivationPayments();

    useEffect(() => {
        let isMounted = true;

        const getGalleryInfo = async () => {
            try {
                const galleryInfo = await getGallery();
                const galleryPaymentInfo = await getActivationPaymentSettings(
                    galleryInfo?.activationId,
                );

                if (token) {
                    const paymentInfo = await markTokenAsClaimed(
                        token,
                        galleryInfo?.activationId,
                    );

                    isMounted && setSessionPayment(paymentInfo);
                    if (!paymentInfo.invalidatedAt) {
                        const paymentIntent = await createPaymentIntent(
                            galleryInfo?.activationId,
                            token,
                        );
                        isMounted && setPaymentIntent(paymentIntent);
                    }
                }

                setOptions({
                    amount: galleryPaymentInfo?.costInCents,
                    automaticPaymentMethods: {
                        allowRedirects: 'never',
                        enabled: true,
                    },
                    mode: 'payment',
                    currency: 'usd',
                    description: 'Photo Booth Session Payment',
                });

                isMounted && setGalleryInfo(galleryInfo);
                isMounted && setGalleryPaymentSettings(galleryPaymentInfo);
            } catch (e) {
                console.error(e);
            } finally {
                isMounted && setIsLoading(false);
            }
        };

        getGalleryInfo();

        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (sessionPayment?.paidAt) {
            setIsPaymentConfirmed(true);
        }
    }, [sessionPayment]);

    useEffect(() => {
        if (sessionPayment?.invalidatedAt) {
            setIsPaymentInvalidated(true);
        }
    }, [sessionPayment]);

    const onSuccess = async () => {
        await markPaymentAsPaid(
            token ?? '',
            galleryInfo?.activationId ?? 0,
            paymentIntent?.id,
        );
        setIsPaymentConfirmed(true);
    };

    if (isLoading || !options) {
        return <Loading isLoading={true} />;
    }

    return (
        <div>
            <GlobalStyle
                foregroundColor={galleryInfo?.foregroundColor}
                backgroundColor={galleryInfo?.backgroundColor}
                headerBackgroundColor={galleryInfo?.headerBackgroundColor}
                headerForegroundColor={galleryInfo?.headerForegroundColor}
            />
            <div className={`Gallery-header`}>
                <div className="Gallery-header-title">
                    <img
                        src={galleryInfo?.logo || logo}
                        alt="Smilebooth Logo"
                    />
                </div>
                <div className="Gallery-name">{galleryInfo?.name}</div>
            </div>
            <div className="flex justify-center mt-[86px]">
                <div className="spacer" />
                <div className="Payment-form-container w-[500px] p-[12px]">
                    <div className="text-center text-[16px] mb-[24px]">
                        {isPaymentConfirmed && (
                            <>
                                <span className="font-bold text-[36px]">
                                    Let's take some photos!
                                </span>
                                <br />
                                <br />
                                <span className="text-[16px]">
                                    Thank you so much for your payment! Check
                                    the photo booth screen to start.
                                </span>
                            </>
                        )}

                        {!isPaymentConfirmed && !isPaymentInvalidated ? (
                            <>
                                <span className="font-bold text-[36px]">
                                    Please pay to begin
                                </span>
                                <br />
                                <br />
                                <span className="text-[72px] font-bold">
                                    ${options.amount / 100}
                                </span>
                                <br />
                                <br />
                                <span className="text-[16px]">
                                    After a successful payment, check the photo
                                    booth screen to start your photo session.
                                </span>

                                {error && (
                                    <div className="error text-center mt-[24px] text-[#ff0000]">
                                        {error}
                                    </div>
                                )}
                            </>
                        ) : null}

                        {isPaymentInvalidated ? (
                            <div className="text-center mt-[24px] text-[#ff0000]">
                                <span className="font-bold text-[36px]">
                                    Session Has Expired
                                </span>
                                <br />
                                <br />
                                <span className="text-[16px]">
                                    The session has expired. Please scan the QR
                                    code again to start a new session.
                                </span>
                            </div>
                        ) : null}
                    </div>
                    {!isPaymentConfirmed && !isPaymentInvalidated && (
                        <Elements stripe={stripePromise} options={options}>
                            <PaymentForm
                                clientSecret={paymentIntent?.clientSecret}
                                setError={setError}
                                onSuccess={onSuccess}
                            />
                        </Elements>
                    )}
                </div>
                <div className="spacer" />
            </div>
        </div>
    );
}

import { CopyCheckIcon, CheckCircle, Circle } from 'lucide-react';
import { DialogTitle } from '@headlessui/react';
import { useMemo, useState } from 'react';

const getElementTypeColor = (elementType: string) => {
    switch (elementType) {
        case 'button':
            return 'bg-blue-100 text-blue-800';
        case 'text':
            return 'bg-green-100 text-green-800';
        case 'image':
            return 'bg-purple-100 text-purple-800';
        case 'outputPreview':
            return 'bg-yellow-100 text-yellow-800';
        case 'currentSessionQR':
            return 'bg-pink-100 text-pink-800';
        default:
            return 'bg-gray-100 text-gray-800';
    }
};

export default function ReplicateElementDialog({
    elements,
    bulkCopyTargetElements,
    setBulkCopyTargetElements,
}: {
    elements: [
        {
            elementId: string;
            element: any;
            elementType: UIElementType;
            screenId: string;
        },
    ];
    bulkCopyTargetElements: string[];
    setBulkCopyTargetElements: (elements: string[]) => void;
}) {
    const [selectedElementTypes, setSelectedElementTypes] = useState<
        UIElementType[]
    >(['button', 'text', 'image', 'outputPreview', 'currentSessionQR']);

    const groupedElements = useMemo(() => {
        return elements.reduce((acc, element) => {
            if (!acc[element.screenId]) {
                // @ts-ignore
                acc[element.screenId] = [];
            }

            if (selectedElementTypes.includes(element.elementType)) {
                acc[element.screenId].push(element);
            }
            return acc;
        }, {} as Record<string, typeof elements>);
    }, [elements, selectedElementTypes]);

    return (
        <div className="sm:flex sm:items-start">
            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-[99999px] bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                <CopyCheckIcon
                    aria-hidden="true"
                    className="h-6 w-6 text-green-600"
                />
            </div>
            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                <DialogTitle
                    as="h3"
                    className="text-base font-semibold leading-6 text-gray-900"
                >
                    The Replicate Tool
                </DialogTitle>
                <div className="mt-2">
                    <p className="text-sm text-gray-500">
                        The Replicate Tool allows you to copy the display
                        properties from one element to any other element.
                    </p>
                </div>
                <div className="mt-2">
                    <p className="text-sm text-gray-500">
                        Select the elements you want to replicate the display
                        properties to.
                    </p>
                </div>
                <div className="mt-2">
                    <div className="flex flex-col gap-2">
                        <span className="isolate inline-flex rounded-md shadow-sm">
                            <button
                                type="button"
                                onClick={() => {
                                    setSelectedElementTypes(
                                        selectedElementTypes.includes('button')
                                            ? selectedElementTypes.filter(
                                                  (type) => type !== 'button',
                                              )
                                            : [
                                                  ...selectedElementTypes,
                                                  'button',
                                              ],
                                    );
                                }}
                                className={`relative inline-flex items-center rounded-l-lg px-2 py-1 text-xs font-semibold ring-1 ring-inset ring-gray-300 cursor-pointer focus:z-10 ${
                                    selectedElementTypes.includes('button')
                                        ? 'bg-white text-black'
                                        : 'bg-gray-200 text-gray-500'
                                }`}
                            >
                                Button
                            </button>
                            <button
                                type="button"
                                onClick={() => {
                                    setSelectedElementTypes(
                                        selectedElementTypes.includes('text')
                                            ? selectedElementTypes.filter(
                                                  (type) => type !== 'text',
                                              )
                                            : [...selectedElementTypes, 'text'],
                                    );
                                }}
                                className={`relative -ml-px inline-flex items-center px-2 py-1 text-xs font-semibold ring-1 ring-inset ring-gray-300 cursor-pointer focus:z-10 ${
                                    selectedElementTypes.includes('text')
                                        ? 'bg-white text-black'
                                        : 'bg-gray-200 text-gray-500'
                                }`}
                            >
                                Text
                            </button>
                            <button
                                type="button"
                                onClick={() => {
                                    setSelectedElementTypes(
                                        selectedElementTypes.includes('image')
                                            ? selectedElementTypes.filter(
                                                  (type) => type !== 'image',
                                              )
                                            : [
                                                  ...selectedElementTypes,
                                                  'image',
                                              ],
                                    );
                                }}
                                className={`relative -ml-px inline-flex items-center px-2 py-1 text-xs font-semibold ring-1 ring-inset ring-gray-300 cursor-pointer focus:z-10 ${
                                    selectedElementTypes.includes('image')
                                        ? 'bg-white text-black'
                                        : 'bg-gray-200 text-gray-500'
                                }`}
                            >
                                Image
                            </button>
                            <button
                                type="button"
                                onClick={() => {
                                    setSelectedElementTypes(
                                        selectedElementTypes.includes(
                                            'currentSessionQR',
                                        )
                                            ? selectedElementTypes.filter(
                                                  (type) =>
                                                      type !==
                                                      'currentSessionQR',
                                              )
                                            : [
                                                  ...selectedElementTypes,
                                                  'currentSessionQR',
                                              ],
                                    );
                                }}
                                className={`relative -ml-px inline-flex items-center px-2 py-1 text-xs font-semibold ring-1 ring-inset ring-gray-300 cursor-pointer focus:z-10 ${
                                    selectedElementTypes.includes(
                                        'currentSessionQR',
                                    )
                                        ? 'bg-white text-black'
                                        : 'bg-gray-200 text-gray-500'
                                }`}
                            >
                                Session QR
                            </button>
                            <button
                                type="button"
                                onClick={() => {
                                    setSelectedElementTypes(
                                        selectedElementTypes.includes(
                                            'outputPreview',
                                        )
                                            ? selectedElementTypes.filter(
                                                  (type) =>
                                                      type !== 'outputPreview',
                                              )
                                            : [
                                                  ...selectedElementTypes,
                                                  'outputPreview',
                                              ],
                                    );
                                }}
                                className={`relative -ml-px inline-flex items-center rounded-r-lg px-2 py-1 text-xs font-semibold ring-1 ring-inset ring-gray-300 cursor-pointer focus:z-10 ${
                                    selectedElementTypes.includes(
                                        'outputPreview',
                                    )
                                        ? 'bg-white text-black'
                                        : 'bg-gray-200 text-gray-500'
                                }`}
                            >
                                Output Preview
                            </button>
                        </span>

                        <div className="mt-2 max-h-[300px] overflow-y-auto">
                            {Object.entries(groupedElements).map(
                                ([screenId, screenElements]) => (
                                    <div key={screenId} className="mb-4">
                                        <h4 className="text-sm font-semibold mb-2">
                                            Screen: {screenId}
                                        </h4>
                                        {screenElements.length > 0 ? (
                                            screenElements.map((element) => {
                                                // TODO: Create utility to generate element keys and parse them.
                                                const elementKey = `${element.screenId}:${element.elementId}:${element.elementType}`;
                                                const isSelected =
                                                    bulkCopyTargetElements.includes(
                                                        elementKey,
                                                    );
                                                return (
                                                    <div
                                                        key={elementKey}
                                                        className="flex items-center gap-2 cursor-pointer py-1 hover:bg-gray-100"
                                                        onClick={() => {
                                                            if (isSelected) {
                                                                setBulkCopyTargetElements(
                                                                    bulkCopyTargetElements.filter(
                                                                        (id) =>
                                                                            id !==
                                                                            elementKey,
                                                                    ),
                                                                );
                                                            } else {
                                                                setBulkCopyTargetElements(
                                                                    [
                                                                        ...bulkCopyTargetElements,
                                                                        elementKey,
                                                                    ],
                                                                );
                                                            }
                                                        }}
                                                    >
                                                        {isSelected ? (
                                                            <CheckCircle className="h-4 w-4 text-blue-500" />
                                                        ) : (
                                                            <Circle className="h-4 w-4 text-gray-300" />
                                                        )}
                                                        <span
                                                            className={
                                                                isSelected
                                                                    ? 'font-semibold'
                                                                    : ''
                                                            }
                                                        >
                                                            {element.elementId}
                                                        </span>
                                                        <span className="flex-1" />
                                                        <span
                                                            className={`px-2 py-1 rounded-[99999px] text-xs font-medium ${getElementTypeColor(
                                                                element.elementType,
                                                            )}`}
                                                        >
                                                            {
                                                                element.elementType
                                                            }
                                                        </span>
                                                    </div>
                                                );
                                            })
                                        ) : (
                                            <div className="text-sm text-gray-500 italic pl-4">
                                                No elements available for this
                                                screen
                                            </div>
                                        )}
                                    </div>
                                ),
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

import { CopyCheckIcon } from 'lucide-react';
import { DialogTitle } from '@headlessui/react';

export default function ReplicateScreenDialog({
    screens,
    bulkCopyTargetScreens,
    setBulkCopyTargetScreens,
}: {
    screens: Record<string, ScreenDefinition>;
    bulkCopyTargetScreens: string[];
    setBulkCopyTargetScreens: (screens: string[]) => void;
}) {
    return (
        <div className="sm:flex sm:items-start">
            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-[99999px] bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                <CopyCheckIcon
                    aria-hidden="true"
                    className="h-6 w-6 text-green-600"
                />
            </div>
            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                <DialogTitle
                    as="h3"
                    className="text-base font-semibold leading-6 text-gray-900"
                >
                    The Replicate Tool
                </DialogTitle>
                <div className="mt-2">
                    <p className="text-sm text-gray-500">
                        The Replicate Tool allows you to copy the display
                        properties from one screen to any other screen.
                    </p>
                </div>
                <div className="mt-2">
                    <p className="text-sm text-gray-500">
                        Select the screens you want to replicate the display
                        properties to.
                    </p>
                </div>
                <div className="mt-2 max-h-[300px] overflow-y-scroll">
                    {Object.keys(screens).map((screenId: string) => {
                        let screen = screens[screenId];
                        return (
                            <div
                                key={screen.id}
                                className="flex items-center gap-2 cursor-pointer"
                            >
                                <span
                                    style={{
                                        fontWeight:
                                            bulkCopyTargetScreens.includes(
                                                screen.id,
                                            )
                                                ? 'bold'
                                                : 'normal',
                                    }}
                                    onClick={() => {
                                        if (
                                            bulkCopyTargetScreens.includes(
                                                screen.id,
                                            )
                                        ) {
                                            setBulkCopyTargetScreens(
                                                bulkCopyTargetScreens.filter(
                                                    (id) => id !== screen.id,
                                                ),
                                            );
                                        } else {
                                            setBulkCopyTargetScreens([
                                                ...bulkCopyTargetScreens,
                                                screen.id,
                                            ]);
                                        }
                                    }}
                                >
                                    {screen.id}
                                </span>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

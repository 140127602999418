import Loading from '../../Common/components/Loading';
import './Gallery.css';
import logo from './white_logo.png';
import { useEffect } from 'react';
import useGalleryViewLogger from '../../Stats/components/hooks/useGalleryViewLogger';
import { Masonry } from 'masonic';
import DeepLocalPreviewImage from './DeepLocalPreviewImage';

interface GalleryViewProps {
    gallery: GalleryObject | null;
    isLoading?: boolean;
    photos: MediaInstance[];
    delayed?: boolean;
    expandedIndex: ShareIdentifier;
    setExpandedIndex: (expandedIndex: ShareIdentifier) => void;
    swipeHandlers: any;
    loadedPhotos: MediaInstance[];
    getNewPage: (size: number) => void;
    message?: string | React.ReactNode;
}

export default function DeepLocalGalleryView({
    gallery,
    isLoading = false,
    photos,
    delayed = true,
    expandedIndex,
    setExpandedIndex,
    swipeHandlers,
    message = '',
}: GalleryViewProps) {
    const hasCTA = gallery?.ctaImage || gallery?.ctaText;
    const galleryId = gallery?.id || 0;
    const { logGalleryView } = useGalleryViewLogger();

    useEffect(() => {
        if (galleryId) {
            logGalleryView(galleryId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [galleryId]);

    const GalleryPreviewImageWrapper = ({
        data,
        index,
    }: {
        data: MediaInstance;
        index: number;
    }) => {
        return (
            <DeepLocalPreviewImage
                media={data}
                key={data.shareIdentifier}
                isExpanded={expandedIndex === data.shareIdentifier}
                setExpandedIndex={setExpandedIndex}
                index={index}
                galleryInfo={gallery}
            />
        );
    };

    return (
        <>
            <Loading isLoading={isLoading} />
            {!isLoading && (
                <div
                    className={`Gallery-header ${
                        expandedIndex != null ? 'hidden' : ''
                    }`}
                >
                    <div className="Gallery-header-title">
                        <img
                            src={gallery?.logo || logo}
                            alt="Smilebooth Logo"
                        />
                    </div>
                    <div className="Gallery-name">{gallery?.name}</div>
                </div>
            )}
            <div className="Gallery-content pb-[20px]" id="galleryContainer">
                <div className="Gallery-grid-container">
                    <div
                        {...swipeHandlers}
                        className={`Gallery-grid ${delayed ? '' : 'hide'}`}
                    >
                        <Masonry
                            items={photos}
                            render={GalleryPreviewImageWrapper}
                            columnGutter={12}
                            rowGutter={12}
                            maxColumnCount={5}
                            columnWidth={400}
                        />
                    </div>
                </div>
                {hasCTA ? (
                    <div
                        className="Gallery-cta"
                        onClick={() => {
                            if (gallery?.ctaLink) {
                                window.open(gallery?.ctaLink, '_blank');
                            }
                        }}
                    >
                        {gallery?.ctaImage && (
                            <img src={gallery?.ctaImage} alt="Smilebooth CTA" />
                        )}
                        {gallery?.ctaText && <p>{gallery?.ctaText}</p>}
                    </div>
                ) : null}

                {message ? (
                    <div className="Gallery-message">
                        <p>{message}</p>
                    </div>
                ) : null}
            </div>
        </>
    );
}

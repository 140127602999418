import { useState, useEffect } from 'react';
import useGallery from './useGallery';
import useGalleryBehaviors from './useGalleryBehvaiors';

export default function useSharingInteractions(slug: string) {
    const [isLoading, setIsLoading] = useState(true);
    const [photos, setPhotos] = useState<MediaInstance[]>([]);
    const [delayed, setDelayed] = useState(false);
    const [gallery, setGallery] = useState<GalleryObject | null>(null);
    const [loadedPhotos, setLoadedPhotos] = useState<MediaInstance[]>([]);
    const [expandedIndex, setExpandedIndex] = useState<ShareIdentifier>(null);

    const { getGallery, getActivationPhotos } = useGallery({
        slug,
        galleryId: gallery?.id || 0,
    });

    const getNewPage = (count: number) => {
        if (
            photos.length === loadedPhotos.length &&
            loadedPhotos.length !== 0
        ) {
            return;
        }

        setLoadedPhotos([
            ...loadedPhotos,
            ...photos.slice(
                loadedPhotos.length - 1,
                loadedPhotos.length + count - 1,
            ),
        ]);
    };

    const advance = () => {
        const locatedIndex = loadedPhotos.findIndex(
            (photo) => photo.shareIdentifier === expandedIndex,
        );

        if (locatedIndex === loadedPhotos.length - 1) {
            getNewPage(10);
        }
        if (locatedIndex < photos.length) {
            setExpandedIndex(loadedPhotos[locatedIndex + 1].shareIdentifier);
        } else {
            setExpandedIndex(null);
        }

        console.log('Advance');
    };

    const reverse = () => {
        const locatedIndex = loadedPhotos.findIndex(
            (photo) => photo.shareIdentifier === expandedIndex,
        );

        setExpandedIndex(
            locatedIndex > 0
                ? loadedPhotos[locatedIndex - 1].shareIdentifier
                : null,
        );
        console.log('Reverse');
    };

    const { swipeHandlers } = useGalleryBehaviors(
        setExpandedIndex,
        advance,
        reverse,
    );

    useEffect(() => {
        if (!isLoading) {
            setTimeout(() => setDelayed(true), 100);
        }
    }, [isLoading]);

    useEffect(() => {
        setLoadedPhotos([
            ...photos.slice(0, window?.outerWidth > 1500 ? 20 : 10),
        ]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [photos.length]);

    useEffect(() => {
        let isMounted = true;
        const load = async () => {
            try {
                const gallery = await getGallery();
                const newPhotos = await getActivationPhotos(
                    gallery.activationId,
                );

                setGallery(gallery);
                if (photos.length !== newPhotos.length) {
                    setPhotos(newPhotos);
                }
            } catch (e) {
                console.error(e);
            } finally {
                isMounted && setIsLoading(false);
            }
        };

        load();

        let interval = setInterval(() => {
            const fetchGallery = async () => {
                try {
                    const photos = await getActivationPhotos(
                        gallery?.activationId || 0,
                    );

                    isMounted && setPhotos(photos);
                } catch (e) {
                    console.error(e);
                } finally {
                    isMounted && setIsLoading(false);
                }
            };
            fetchGallery();
        }, 15 * 1000);

        return () => {
            isMounted = false;
            clearInterval(interval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gallery?.activationId]);

    return {
        photos,
        isLoading,
        gallery,
        swipeHandlers,
        expandedIndex,
        setExpandedIndex,
        delayed,
        loadedPhotos,
        getNewPage,
    };
}
